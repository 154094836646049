.app-main {
    background: #00AFD8;
    min-height: 100vh;
    width: 100%;
    color: rgba(0, 0, 0, 1);
}



@media (pointer:none), (pointer:coarse) {
    .app-main{
       
      }
}
.home {
    max-width: 100%;
    text-align: center;
}

.main-img-container {
    padding-top: 10vh;
    width: 80vw;
    margin: auto;
    margin-top: -20px;
}

.main-img-container img {
    width: 35vw;

}

.home-basic-info h1 {
    font-size: 2vw;
    padding-bottom: 20px;
}


.home-footer-container {
    margin-top: 200px;
    margin: auto;
}

/*dd*/
.direct-donation-main {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #A0A0A0;
}

.direct-donation-title {
    font-size: 20pt;
    font-weight: 800;
}


.direct-donation-sub {
    display: inline-flex;
    text-align: center;
}

.direct-donation-paypal {
    width: 40vw;
    margin: auto;
    margin-top: 8vh;
    color: black;
    text-decoration: none;
}


.direct-donation-paypal img {
    width: 15vw;
}

.direct-donation-theta {
    width: 40vw;
}

.direct-donation-theta img {
    width: 14vw;
}

.direct-donation-sub button {
    background-color: white;
    color: black;
    font-weight: 700;
    border-radius: 5px;
    width: 20vw;
    font-size: 1vw;
    border-color: black;
}


/*50-50*/
.fifty-50-home {
    background-color: white;
    padding-bottom: 20px;
}

.fifty-50-home-title {
    margin: 20px;
}

.fifty-50-home-title h1 {
    font-size: 4vw;
    font-weight: 700;
}

.fifty-50-home img {
    margin: 20px;
    border-radius: 50vw;
    width: 25vw;
    height: auto;
}

.fifty-50-sub {
    width: 70vw;
    margin: auto;
    border-radius: 10px;
    padding: 20px;
    background-color: #00AFD8;
    display: inline-flex;
}

.fifty-50-contest-info {
    display: inline-block;
    font-weight: 700;
    font-size: 2vw;
}

.fifty-50-contest-info button {
    width: 30vw;
    font-size: 2vw;
    font-weight: 800;
    border-radius: 10px;
    margin-bottom: 20px;
}

.fifty-50-description {
    display: inline-block;
    width: 40vw;
    margin: auto
}

/*Pet to NFT*/
.mint-your-pet-home {
    background-color: #00AFD8;
}

.mint-your-pet-home-sub {
    width: 60vw;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: auto;
}

.mint-your-pet-home-text {
    text-align: center;
    margin: auto;
}

.mint-your-pet-home-text h1 {
    font-size: 3vw;
    font-weight: 800;
}

.mint-your-pet-home-img {
    width: 30vw;
    margin: auto;
}

.mint-your-pet-home-img img {
    width: 10vw;
}

.mint-your-pet-home-button-container {}

.mint-your-pet-home-button-container button {
    background-color: white;
    color: black;
    font-weight: 700;
    font-size: 2vw;
    border-radius: 5px;
    width: 20vw;
    border-color: black;
}


/*NFT Market*/

.NFT-market-main {
    background-color: #00AFD8;
    padding: 20px;
}

.NFT-market-main-header{
    padding: 20px;
}

.NFT-market-main-header h1{
    font-weight: 800;
}

.NFT-market-main-header p{
    font-weight: 500;
}

.nft-market-main-creator-row A{
    color: black;
    font-weight: 600;
}

.nft-market-main-creator-row :hover{
    color: black;
    font-weight: 700;
    text-decoration: none;
}

.nft-market-main-creator-row button{
    border-radius: 5px;
    width: 60vw;
    height: 8vh;
    font-size: 32pt;
}

.nft-market-main-creator-row-content-container{
    width:90vw;
    justify-content: space-evenly;
    margin: auto;
}



.nft-market-main-creator-row-content-container img{
    width: 18vw;
    padding: 1vw;
    margin: auto;
}



/*Merch Home*/
.merch-home {
    background-color: #A0A0A0;
    text-align: center;
    margin: auto;
}

.merch-home h1 {
    font-size: 4vw;
    font-weight: 700;
    font-style: italic;
}

.merch-home-sub {
    display: inline-flex;
    width: 90vw;
    text-align: center;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.merch-product {
    display: inline;
    background-color: white;
    width: 20vw;
    height: 80vh;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    vertical-align: center;
    margin: auto;
}

.merch-product-sub {
    margin-top: 15vh;
}

.merch-product img {
    max-width: 15vw;
    max-height: 50vh;
}

.merch-product-description {
    font-size: 24pt;
    font-weight: 700;
    color: black;
}

.merch-product :hover {
    font-weight: 900;
    text-decoration: none;
}

/*Home About*/
.faqs-home {
    background-color: white;
    max-width: 100vw;
    margin: auto;
}

.faqs-home-sub {
    width: 80vw;
    margin: auto
}

.faqs-home-img-container {
    display: inline-flex;
    max-width: 80vw;
    text-align: center;
    margin: auto;
}

.faqs-home-img-container img {
    max-width: 20vw;
}

@media (max-width: 1000px) {
    .main-img-container img {
        width: 75vw;
        padding-bottom: 1vh;
    }

    /*DD*/
    .direct-donation-sub {
        display: inline-block;
    }

    .direct-donation-paypal {
        width: 80vw;
    }

    .direct-donation-paypal img {
        width: 30vw;
    }

    .direct-donation-theta {
        width: 80vw;
    }

    .direct-donation-theta img {
        width: 30vw;
    }

    .direct-address {
        font-size: 0.75rem;
    }

    .direct-donation-sub button {
        width: 80vw;
    }

    .direct-donation-sub button {
        font-size: 3.5vw;
    }

    /*50-50*/



    .fifty-50-sub {
        width: 90vw;
        display: inline-block;
    }

    .fifty-50-contest-info {
        font-size: 5vw;
    }

    .fifty-50-contest-info button {
        width: 70vw;
        font-size: 5vw;
    }

    .fifty-50-description {
        width: 80vw;
    }

    /*Mint Your Pet*/
    .mint-your-pet-home-sub {
        width: 80vw;
    }

    .mint-your-pet-home-text h1 {
        font-size: 5vw;
    }

    .mint-your-pet-home-button-container button {
        font-size: 4vw;
        width: 50vw;
    }

    /*Merch*/
    /*Merch Home*/
    .merch-home {
        background-color: #A0A0A0;
        text-align: center;
        margin: auto;
    }

    .merch-home h1 {
        font-size: 4vw;
        font-weight: 700;
        font-style: italic;
    }

    .merch-home-sub {
        display: inline-block;
    }

    .merch-product {
        display: inline-block;
        background-color: white;
        width: 75vw;
        height: 60vh;
        border-radius: 10px;
        padding: 10px;
        text-align: center;
        margin: auto;
        margin-top: 20px;
    }

    .merch-product img {
        max-width: 50vw;
        max-height: 50vh; 
    }

    .merch-product-description {
        font-size: 3vw;
        font-weight: 700;
        color: black;
    }

    /*NFT Market*/

    .nft-market-main-creator-row button{
        border-radius: 5px;
        width: 80vw;
        height: 5vh;
        font-size: 20pt;
    }


    /*FAQ*/
    .faqs-home-img-container {
        max-width: 90vw;
    }

    .faqs-home-img-container img {
        max-width: 30vw;
    }

}
.footer-main{
    display: inline-flex;
    width: 95vw;
    margin: auto;
}



@media (max-width: 1000px) {
    .footer-main{
        font-size: 0.6rem;
    }
    
    .text-left{
        text-align: center;
        margin: auto;
    }
    

}

.audits-sub{
    background-color: white;
    width: 50vw;
    margin: auto;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
}
.thank-you-main{
    background-color: white;
    width: 80vw;
    text-align: center;
    border-radius: 10px;
    padding: 10px;
    margin: auto;
}

.thank-you-main img{
    width:30vw;
}

.thank-you-body-text{
    width:40vw;
    margin:auto;
}

.break-bar-thank-you{
    height:10vh;
}


@media (max-width: 1000px) {
    .thank-you-main{
        width: 90vw;
    }

    .thank-you-body-text{
        width:80vw;
        margin:auto;
    }

    .thank-you-main img{
        width:50vw;
    }

}


.mint-your-pet-page-main{
    background: #00AFD8;
    text-align: center;
    width: 100%;
}

.mint-your-pet-page-sub{
    display: inline-block;
    background-color: white;
    width:90vw;
    border-radius: 10px;
    text-align: center;
    margin:auto;
    margin-top: 50px;
    padding: 20px;
}

.mint-your-pet-main-row{
    display: inline-flex;
}

.mint-your-pet-center{
    width: 50vw;
}   

.mint-your-pet-form-text {
    width: 30vw;
    text-align: left;
    margin: auto;
    margin-left: 15vw;
}

.mint-your-pet-preview img{
    width: 30vw;
}

.mint-your-pet-center button{
    width: 40vw;
    height: 5vh;
    border-radius: 5px;
    font-weight: 600;
    text-align: center;
    margin: auto;
}

.mint-your-pet-left img{
    width: 5vw;
}

.mint-your-pet-right img{
    padding-top: 20px;
    width: 5vw;
}


@media (max-width: 1000px) {
    .mint-your-pet-left img{
        width: 0vw;
    }
    
    .mint-your-pet-right img{
        padding-top: 20px;
        width: 0vw;
    }

    .mint-your-pet-main-row{
        display: inline;
    }

    .mint-your-pet-form-text {
        width: 85vw;
        margin: auto;
    }

    .mint-your-pet-preview img{
        width: 80vw;
    }

    .mint-your-pet-center button{
        width: 80vw;
    }
    
}

