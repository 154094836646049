.mint-your-pet-page-main{
    background: #00AFD8;
    text-align: center;
    width: 100%;
}

.mint-your-pet-page-sub{
    display: inline-block;
    background-color: white;
    width:90vw;
    border-radius: 10px;
    text-align: center;
    margin:auto;
    margin-top: 50px;
    padding: 20px;
}

.mint-your-pet-main-row{
    display: inline-flex;
}

.mint-your-pet-center{
    width: 50vw;
}   

.mint-your-pet-form-text {
    width: 30vw;
    text-align: left;
    margin: auto;
    margin-left: 15vw;
}

.mint-your-pet-preview img{
    width: 30vw;
}

.mint-your-pet-center button{
    width: 40vw;
    height: 5vh;
    border-radius: 5px;
    font-weight: 600;
    text-align: center;
    margin: auto;
}

.mint-your-pet-left img{
    width: 5vw;
}

.mint-your-pet-right img{
    padding-top: 20px;
    width: 5vw;
}


@media (max-width: 1000px) {
    .mint-your-pet-left img{
        width: 0vw;
    }
    
    .mint-your-pet-right img{
        padding-top: 20px;
        width: 0vw;
    }

    .mint-your-pet-main-row{
        display: inline;
    }

    .mint-your-pet-form-text {
        width: 85vw;
        margin: auto;
    }

    .mint-your-pet-preview img{
        width: 80vw;
    }

    .mint-your-pet-center button{
        width: 80vw;
    }
    
}
