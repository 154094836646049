.app-main {
    background: #00AFD8;
    min-height: 100vh;
    width: 100%;
    color: rgba(0, 0, 0, 1);
}



@media (pointer:none), (pointer:coarse) {
    .app-main{
       
      }
}