.thank-you-main{
    background-color: white;
    width: 80vw;
    text-align: center;
    border-radius: 10px;
    padding: 10px;
    margin: auto;
}

.thank-you-main img{
    width:30vw;
}

.thank-you-body-text{
    width:40vw;
    margin:auto;
}

.break-bar-thank-you{
    height:10vh;
}


@media (max-width: 1000px) {
    .thank-you-main{
        width: 90vw;
    }

    .thank-you-body-text{
        width:80vw;
        margin:auto;
    }

    .thank-you-main img{
        width:50vw;
    }

}

